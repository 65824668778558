import * as amplitude from '@amplitude/analytics-browser';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { UserData } from 'clipsal-cortex-types/src/api/api-user';

import { RootState } from '../../app/store';
import { get } from '../../common/api/api-helpers';
import { IS_DEMO_LOGIN, IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { SitesResponse } from '../sites/sitesSlice';
import { setupAmplitude, setupLogRocket } from './user-helpers';

export interface User {
  isLoggedIn: boolean;
  hasFetchedUserDetails: boolean;
  token: string;
  role: string;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cognitoUser: CognitoUser | null;
  sendEmailAction: string;
  siteIDs: number[];
  userID: number;
  tenantID: number;
}

export const checkUserLoggedIn = createAsyncThunk('user/checkUserLoggedIn', async () => {
  let session: CognitoUserSession;
  let isLoggedIn = false;
  let idToken = '';
  let accessToken = '';
  let cognitoUser: CognitoUser | null = null;

  if (IS_DEMO_LOGIN) {
    return {
      ...initialState,
      isLoggedIn: true,
    };
  }

  try {
    session = await Auth.currentSession();
    cognitoUser = await Auth.currentUserPoolUser();
    isLoggedIn = true;
    idToken = session.getIdToken().getJwtToken();
    accessToken = session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error(error);
  }

  // Return the successfully authorized user's token.
  return {
    ...initialState,
    cognitoUser,
    isLoggedIn,
    accessToken,
    token: idToken,
  };
});

/**
 * Fetches user details from the `User` end-point.
 */
export const fetchUserDetails = createAsyncThunk<
  User,
  { isProduction: boolean; isNotDevelopment: boolean },
  { state: RootState }
>('user/fetchUserDetails', async ({ isProduction, isNotDevelopment }, { getState }) => {
  let userData: UserData | null = null;
  let sites: SitesResponse | null = null;

  try {
    [userData, sites] = await Promise.all([
      get<UserData>('/v1/user'),
      get<SitesResponse>('/v1/sites?limit=2&offset=0'),
    ]);
  } catch (error) {
    throw new Error((error as Error).message);
  }

  // Only send events to sentry in staging and production environments
  if (isNotDevelopment && userData?.user_id) {
    Sentry.setUser({ id: userData.user_id.toString() });
  }

  // Setup LogRocket _only_ for authorized users who are logged in.
  if (userData?.user_id && userData.role !== 'SUPER_ADMIN' && isNotDevelopment && !IS_RUNNING_CYPRESS_TESTS) {
    await setupAmplitude(userData);

    if (isProduction) setupLogRocket(userData);
  }

  return {
    ...getState().user,
    hasFetchedUserDetails: !!userData,
    email: userData?.email,
    firstName: userData?.first_name,
    lastName: userData?.last_name,
    phone: userData?.phone,
    sendBillsAction: userData?.send_bills_action,
    siteIDs: sites.data.map((site) => site.site_id),
    userID: userData?.user_id,
    role: userData?.role,
    tenantID: userData?.tenant_id,
  };
});

export const initialState: User = {
  isLoggedIn: false,
  hasFetchedUserDetails: false,
  cognitoUser: null,
  token: '',
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phone: '',
  sendEmailAction: '',
  siteIDs: [],
  userID: 0,
  tenantID: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    },
    logOut: () => {
      if (IS_PRODUCTION_BUILD) amplitude.reset();
      return initialState;
    },
    updateUserDetails: (state, action: PayloadAction<Partial<User>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
          token: action.payload.token,
          cognitoUser: action.payload.cognitoUser,
          accessToken: action.payload.accessToken,
        };
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        return action.payload ? action.payload : state;
      })
      .addCase(fetchUserDetails.rejected, () => {
        return initialState;
      });
  },
});

export const { logIn, logOut, updateUserDetails } = userSlice.actions;

export const selectUser = (state: RootState): User => {
  return state.user;
};

export const { reducer: userReducer } = userSlice;
