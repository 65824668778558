import { Capacitor } from '@capacitor/core';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MODBUS_CONFIGURABLE_METER_MODELS = ['6MW-CER'];
export const SWITCH_CONFIGURABLE_METER_MODELS = ['6M+3SW', '6M4+3SW', '6W+3SW', '6MW-CER', 'SR6'];
// 6M4 , 6W, 6W+3SW also might have Wi-Fi, but you can't configure it.
export const WIFI_CONFIGURABLE_METER_MODELS = ['6MW', '6MW-CER'];
export const WIFI_ONLY_METER_MODELS = ['6W', '6W+3SW'];
export const IS_WEB = Capacitor.getPlatform() === 'web';
export const IS_IOS = Capacitor.getPlatform() === 'ios';
export const IS_ANDROID = Capacitor.getPlatform() === 'android';
export const IS_NATIVE = Capacitor.isNativePlatform();

// Static constant IDs from the production DB
// (this is a bit of a smell, probably best to reconsider how this is discerned).
export const SOLAR_EDGE_MANUFACTURER_ID = 60;
export const ALPHA_ESS_MANUFACTURER_ID = 1;
export const GOODWE_MANUFACTURER_ID = 21;
export const TESLA_MANUFACTURER_ID = 70;
export const SENSIBO_MANUFACTURER_ID = 73;
export const LGES_MANUFACTURER_ID = 74;
export const SUNGROW_MANUFACTURER_ID = 65;
export const SCHNEIDER_ELECTRIC_MANUFACTURER_ID = 52;
export const WATTWATCHERS_MANUFACTURER_ID = 72;
export const SOLAR_ANALYTICS_MANUFACTURER_ID = 59;
export const CATCH_POWER_MANUFACTURER_ID = 482;

export const ENERGY_MASTERS_TENANT_ID = 364;

export const WW_MODEL_TO_CLIPSAL_MODEL_MAP: Record<string, string> = {
  '6MW-CER': 'CX2-MB-SW',
  '6M4': 'CX1',
  '6M': 'CX1',
  '6M4+3SW': 'CX1-SW',
  '6M+3SW': 'CX1-SW',
};
